import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Eko from "/src/components/img/eko"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

export default () => (
  <Layout>
    <SEO title="Klasifikace lesů" />
    <h1>Klasifikace lesů</h1>
    <p>Lesy lze klasifikovat podle mnoha kritérií, zde jsou vyjmenovány typy lesů ve skupinách, které se používají v&nbsp;praxi českého lesnictví.</p>
    <hr />
    <h2>Funkční zaměření lesa</h2>
    <p>Lesy můžeme dělit podle jeho funkčního zaměření na 3 základní skupiny:</p>
    <ul>
    <li><strong>lesy hospodářské</strong></li>
    <li><strong>lesy ochranné</strong>
    <ul>
    <li>mimořádně nepříznivá stanoviště</li>
    <li>horské lesy pod hranicí stromů</li>
    <li>porosty nad hranicí stromů</li>
    <li>větrolamy</li>
    </ul>
    </li>
    <li><strong>lesy zvláštního určení</strong>
    <ul>
    <li>ze zákona</li>
    <li>na návrh</li>
    </ul>
    </li>
    </ul>
    <h3>Lesy hospodářské</h3>
    <p><strong>Lesů hospodářských</strong> je většina (kolem 80 %), primární funkcí je produkce lesní hmoty a zisk. Ekologické funkce plní <u>úplavem</u>, to znamená mimochodem.</p>
    <h3>Lesy ochranné</h3>
    <p><strong>Lesy ochranné</strong> nechrání z&nbsp;hlediska ochrany přírody, ale z&nbsp;hlediska ochrany půdy před erozí či před lavinami.</p>
    <p>Nalezneme je často na mimořádně nepříznivých stanovištích, tedy tam, kde by mohlo dojít k&nbsp;velmi silné erozi či dokonce zániku půdy. Případná necitlivá těžba by mohla tak vést k&nbsp;zániku lesní půdy, proto LHP často radí do těchto stanovišť vůbec nezasahovat či zde dělat jen zdravotní probírky. Typickými stanovišti jsou balvaniště, kde může docházet k&nbsp;<u>intraskeletové erozi</u> (splavování skeletu a částeček hluboko mezi balvany), dále suťoviště, skály či silně podmáčená stanoviště.</p>
    <p>Dále do nich spadají horské lesy pod hranicí lesa (horní hranice lesa = stromy s&nbsp;výškou 5 metrů), které tvoří řídké porosty a chrání níže položené lesy před nebezpečím mur, což jsou bahnito-kamenité proudy vody. Pás mezi horní hranicí stromů a zapojeným lesem bývá zhruba 200 až 300 metrů široký.</p>
    <p>Dále do těchto lesů patří porosty nad horní hranicí stromů, ačkoliv jde často o alpínské louky jen s&nbsp;hloučky stromů, které nedosahují výšky ani 5 metrů, jsou vedeny jako lesní půda. Tyto půdy totiž byla snaha zalesňovat kvůli ochraně před murami a povodněmi.</p>
    <p>Též sem patří porosty, které tvoří větrolamy pro ochranu zemědělské půdy a které spadly pod správu lesáků, patří do kategorie lesů ochranných.</p>
    <h3>Lesy zvláštního určení</h3>
    <p><strong>Lesy zvláštního určení</strong> jsou takové, kde jiné, veřejné zájmy zřetelně převažují nad zájmy produkce dřeva. Ze zákona sem patří lesy v&nbsp;ochranných pásmech vodních zdrojů I. stupně, minerálních vod a lesy v&nbsp;území národních parků, národních přírodních památek a rezervacích. Dříve, ve starém zákonu, byly do této kategorie automaticky zařazeny ještě imisní lesy.</p>
    <p>Dále se jím může stát hospodářský les na základě návrhu, a to buď samotného vlastníka (většinou pouze stát) či orgánů státní správy. V&nbsp;praxi jde o lesy prvních zón CHKO, přírodních památek a rezervací. Dále jde o lesy příměstské a rekreační (okolí Brněnské přehrady), lázeňské, lesního výzkumu a lesnické výuky (ŠLP Křtiny) či o lesy v&nbsp;uznaných oborách a samostatných bažantnicích.</p>
    <hr />
    <h2>Hospodářský tvar lesů</h2>
    <p>Podle hospodářského tvaru lesa vymezujeme tři kategorie:</p>
    <ul>
    <li><strong>vysoký les (semenný)</strong></li>
    <li><strong>nízký les (výmladkový)</strong></li>
    <li><strong>střední les (sdružený)</strong></li>
    </ul>
    <h3>Vysoký les</h3>
    <p><strong>Vysoký les</strong> vzniká výsadbou malých stromků či přirozeným zmlazením – vysemeněním. V&nbsp;naší krajině činí jejich zastoupení 96 %, stromy totiž nejsou křivé, rostou rovně a relativně vysoko. Dlouhé a rovné klády, které jsou produktem tohoto typu lesa, bývají pak hospodářsky nejcennější.</p>
    <h3>Nízký les</h3>
    <p><strong>Nízký les</strong> je pařezina (zmlazení z&nbsp;pařezu, z&nbsp;nějž vyrazí nové pruty). Získané dřevo je vhodné například na topivo, protože je tenčí a křivější. Tento typ lesa byl v&nbsp;minulosti zakázán, ale zákonem byl v&nbsp;roce 1995 opět povolen. I přesto se kvůli relativní ekonomické nevýhodnosti tento dříve velmi častý typ lesa znovu nerozšířil.</p>
    <p>V&nbsp;minulosti se využíval proto, že ihned po skácení vyletěli nové pruty nahoru. Roční přírůstek tak mohl činit klidně 1,5 metru. Do 40 let zde přirostlo více hmoty než v&nbsp;lese vysokém, ovšem hodnota této dřevní hmoty byla nižší. V&nbsp;případě, kdy bylo dříví těženo pro palivo, nižší hodnota dříví kvůli křivosti a tenkosti nevadila. Dnes však máme již levnější zdroje energie.</p>
    <p>Nízký les se však může například uplatnit jako prutník (zdroj prutů pro košíkaření), tříslový les (stromy pro chemický průmysl), v&nbsp;některých případech i dnes jako energetický les (jako zdroj dřevěného uhlí a paliva) a užitkový les (pro ostatní účely).</p>
    <h3>Střední les</h3>
    <p><strong>Střední les</strong> je kombinací vysokého a nízkého lesa. Jde o výmladkový les, v&nbsp;němž několik nejkvalitnějších jedinců roste jako les vysoký. Při vytěžení tak nevzniká úplná holina, nedochází k&nbsp;mineralizaci humusu a změnám mikroklimatu. Je zde přítomen víceetážový porost a jde tak o napodobení přirozeného lesa – kultura není stejnověká.</p>
    <p>Hospodářsky není však střední les příliš pohodlný, jsou zde problémy s&nbsp;výběrem stromů ke kácení, pádem jednotlivých stromů pak mohou vznikat škody na okolních porostech.</p>
    <p>Ponechané stromy se nazývají <u>výstavky,</u> ty se však málokdy objevují v&nbsp;množství 50-100 ks na hektar, jak je u středního lesa běžné. V&nbsp;menších počtech či jednotlivě se však můžou objevit i v&nbsp;hospodářských lesích – například může jít o skupinu buků uprostřed smrkové plantáže či u křižovatky cest může být nějaký výstavek ponechán.</p>
    <p>Střední les často měli sedláci, ve kterém nechávali růst výstavky například na pozednice (dlouhé těžké trámy na krovy). Rovněž je využívali například pro tesání žlabů pro dobytek. Nejčastěji šlo o duby a buky. Dříví z&nbsp;nízké části lesa pak použili převážně pro palivo.</p>
    <hr />
    <h2>Způsob hospodaření v&nbsp;lese</h2>
    <p>Podle způsoby hospodaření lze rozlišit tři typy lesa:</p>
    <ul>
    <li><strong>pasečný les</strong></li>
    <li><strong>podrostní les</strong></li>
    <li><strong>výběrný les</strong></li>
    </ul>
    <h3>Pasečný les</h3>
    <p><strong>Pasečný les</strong> je dědictvím saské lesnické školy. Při těžbě se vytvoří holá seč, jejíž rozloha může být dle zákona maximálně 1 ha a šíře seče (kácené plochy) může maximálně dvakrát překročit výšku porostu. Pokud se kácí na exponovaném stanovišti, šíře seče může dosáhnout maximálně jednonásobek výše porostu. Toto opatření zabraňuje silnému rozběhu větru, který by pak poškozoval sazenice i okolní stromy, a zajišťuje přístin, díky němuž půda méně vysychá a malé stromky jsou chráněny před úpalem a přehřátím pletiv.</p>
    <p>Po vytvoření seče obvykle stoupá zamokření (zastavena intercepce od stromů), mineralizuje humus a dochází k&nbsp;obohacování stanoviště o živiny a začíná se zde rozmáhat <u>buřeň</u>, tedy přízemní porost, který by zahlušoval nově vysazenou kulturu. Paseka se proto musí podle zákona do 2 let zalesnit. Kácení by též mělo probíhat v&nbsp;zimě.</p>
    <p>Výjimky ohledně rozměrů pasek povoluje orgán státní správy lesů, na přirozených borových stanovištích na píscích a lužních stanovištích je povolena velikost seče až 2 ha, bez šířkového omezení. Často se zde uplatňuje <em>celoplošná příprava půdy</em> zahrnující shrnutí půdy i s&nbsp;pařezy buldozerem a následné strojové sázení, v&nbsp;případě lužních lesů vypadá často tak, že půdu připraví fréza s&nbsp;pilami, která substrát rozřeže do hloubky 30 cm.</p>
    <p>Další výjimku tvoří horské svahy nad 250 metrů, kde je možná plocha seče až 2 ha, aby mohla paseka dojít až k&nbsp;nějaké cestě. Pokud by ani tak k&nbsp;žádné nedošel, často se tento problém alternativně řeší lanovkou na dříví.</p>
    <h3>Podrostní les</h3>
    <p><strong>Podrostní les</strong> je ekologický ideál, nicméně není příliš běžný. Dochází zde k&nbsp;obnově stromů pod původními stromy, tento způsob je však v&nbsp;podmínkách ČR silně ohrožený přezvěřením – podrost je tak nutno chránit oplocením. Divočáci totiž typicky žerou žaludy a bukvice, malé stromky pak ožírají srnci a jeleni. Nejvíce pak zvěři chutná jedle a v&nbsp;horách jeřáb.</p>
    <p>Při hospodaření v&nbsp;podrostním lese nejprve porušíme korunový zápoj velkou probírkou, tím získáme dříví a zároveň půda trochu více zvlhne a les se prosvětlí. Staré stromy pak více zaplodí, dojde k&nbsp;zaklíčení a vyrostou mladé stromky. Tam, kde vzniknou hluchá místa, se provádí tzv. podsadba (dosazení stromků).</p>
    <p>Tento způsob hospodaření nemá žádné zákonné omezení, v&nbsp;současnosti se praktikuje například v&nbsp;ŠLP Křtiny s&nbsp;Lesech města Brna.</p>
    <h3>Výběrný les</h3>
    <p>Hospodaření ve <strong>výběrném lese</strong> je nejstarší a praktikuje se od nepaměti. Může být provedeno dle různých dimenzí – například mohou být vykáceny největší jedle, buky nad 60 let a tak dále.</p>
    <p>Při této těžbě hrozí závěs stromu, tedy jeden strom se zaklíní do druhého. To může vést k&nbsp;smrtelným zraněním pracovníků v&nbsp;lese a zároveň poškození okolních stromů, které pak mohou být napadeny škůdci a chorobami. Zároveň je třeba dávat pozor na těžební vyrovnanost.</p>
    <hr />
    <ImgCon><Eko /><div>
    <h2>Studijn&iacute; materi&aacute;ly Ekologie a ŽP</h2>
    <p>T&eacute;ma <strong>Klasifikace lesů</strong> je souč&aacute;st&iacute; <Link to="/ekologie-zivotni-prostredi/">studijn&iacute;ch materi&aacute;lů Ekologie a životní prostředí</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte n&aacute;sleduj&iacute;c&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/ekologie-zivotni-prostredi/"><button>Rozcestník ekologie a ŽP</button></Link>
    <Link to="/ekologie-zivotni-prostredi/lesnicka-legislativa/"><button className="inv">&larr; Lesnická legislativa v ČR</button></Link>
    <Link to="/ekologie-zivotni-prostredi/pojmy-lesnictvi/"><button className="inv">Vybrané významné pojmy lesnictví &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
